<template>
  <div class="file-call">
    <button
      class="btn px-3 btn-sm btn-danger"
      style="margin-bottom: 10px;"
      @click="onCancel()"
    >
      {{
        $t('order-confirmation.calls.excel.single-excel.cancel-process-file')
      }}
    </button>

    <button
      class="btn px-3 btn-sm btn-warning mx-2"
      style="margin-bottom: 10px; margin-left: 10px"
      @click="ignoreAll()"
    >
      {{
        $t('order-confirmation.calls.excel.single-excel.ignore-all-corrupted')
      }}
    </button>

    <data-table
      ref="corruptedCallsTable"
      :data="corruptedRows"
      :fields="translatedTableFields"
      :pagination="pagination"
      @changePage="onPageChange($event)"
      :loading="corruptedRowLoading"
    >
      <template v-slot:reasons="{ row }">
        <b-badge
          v-for="reason in row.reasons"
          :key="reason"
          variant="warning"
          class="m-2"
        >
          {{ reason }}
        </b-badge>
      </template>
      <template v-slot:actions="{ row }">
        <button class="btn px-3 btn-sm btn-primary" @click="toggleDetails(row)">
          <i
            :class="
              $refs.corruptedCallsTable.activeRowDetailsId === row.id
                ? 'fa fa-eye-slash'
                : 'fa fa-eye'
            "
          ></i>
        </button>
      </template>
      <template v-slot:details>
        <field
          class="mb-2 w-100"
          v-model="corruptedRowModel.phone"
          :label="
            $t(
              'order-confirmation.calls.excel.single-excel.table.details.form.phone.label'
            )
          "
          :placeholder="
            $t(
              'order-confirmation.calls.excel.single-excel.table.details.form.phone.placeholder'
            )
          "
          :error="fixCorruptedErrors.phone"
        >
        </field>
        <div class="mb-0">
          <label class="font-weight-bold">{{
            $t(
              'order-confirmation.calls.excel.single-excel.table.details.form.order-number.label'
            )
          }}</label>
          <b-form-input
            class="border mb-2"
            v-model="corruptedRowModel.orderNumber"
            :placeholder="
              $t(
                'order-confirmation.calls.excel.single-excel.table.details.form.order-number.placeholder'
              )
            "
          ></b-form-input>
        </div>

        <div class="params-container w-100">
          <div class="params" v-if="!!params.length">
            <h5 class="mt-0">
              {{
                $t(
                  'order-confirmation.calls.excel.single-excel.table.details.form.params'
                )
              }}
            </h5>
            <div
              v-for="(param, idx) in params"
              class="param mb-2"
              :key="param.variable_name"
            >
              <div class="d-flex align-items-center">
                <p class="m-0">{{ param.variable_name }} ({{ param.type }})</p>
                <div
                  class="money-inputs flex-fill"
                  v-if="param.type === 'money'"
                >
                  <div class="flex-fill d-flex">
                    <b-form-input
                      class="mx-2 border pl-2"
                      :placeholder="
                        $t(
                          'order-confirmation.calls.excel.single-excel.table.details.form.amount.placeholder'
                        )
                      "
                      :value="corruptedRowModel.variables[idx].value.amount"
                      @change="
                        corruptedRowModel.variables[idx].value.amount = +$event
                      "
                    ></b-form-input>
                    <b-form-select
                      v-model="corruptedRowModel.variables[idx].value.currency"
                      class="border"
                      :options="currencies"
                    >
                      <template #first>
                        <b-form-select-option :value="undefined" disabled>
                          {{
                            $t(
                              'order-confirmation.calls.excel.single-excel.table.details.form.currency.placeholder'
                            )
                          }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div
                  class="full-number-inputs flex-fill"
                  v-if="param.type === 'full-number'"
                >
                  <div class="flex-fill d-flex">
                    <b-form-input
                      class="mx-2 border pl-2"
                      :placeholder="
                        $t(
                          'order-confirmation.calls.excel.single-excel.table.details.form.value.placeholder'
                        )
                      "
                      v-model="corruptedRowModel.variables[idx].value"
                    ></b-form-input>
                  </div>
                </div>
                <div
                  class="separated-number-inputs flex-fill"
                  v-if="param.type === 'separated-number'"
                >
                  <div class="flex-fill d-flex">
                    <b-form-input
                      class="mx-2 border pl-2"
                      :placeholder="
                        $t(
                          'order-confirmation.calls.excel.single-excel.table.details.form.value.placeholder'
                        )
                      "
                      v-model="corruptedRowModel.variables[idx].value"
                    ></b-form-input>
                  </div>
                </div>
                <div class="text-inputs flex-fill" v-if="param.type === 'text'">
                  <div class="flex-fill d-flex">
                    <b-form-textarea
                      class="mx-2 border pl-2"
                      :placeholder="
                        $t(
                          'order-confirmation.calls.excel.single-excel.table.details.form.value.placeholder'
                        )
                      "
                      v-model="corruptedRowModel.variables[idx].value"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-sm btn-primary" @click="fixCorruptedRow()">
          {{
            $t(
              'order-confirmation.calls.excel.single-excel.table.details.form.fix'
            )
          }}
        </button>
      </template>
    </data-table>
  </div>
</template>

<script>
import orderConfirmationService from '../../services/orderConfirmation.service'
import DataTable from '../../elements/Table.vue'
import { currencies } from '../../data/Currencies'
import { object, string } from 'yup'
import { yupToKV } from '../../utils/yup'
import Field from '../../elements/Field.vue'
import { isValidPhoneNumber } from '../../utils/phone'

export default {
  components: { DataTable, Field },
  data() {
    return {
      fields: [
        {
          accessor: 'status',
          header: 'order-confirmation.calls.excel.single-excel.table.status'
        },
        {
          accessor: 'reasons',
          header: 'order-confirmation.calls.excel.single-excel.table.reasons'
        },
        {
          accessor: 'actions',
          header: 'order-confirmation.calls.excel.single-excel.table.actions'
        }
      ],
      corruptedRows: [],
      corruptedRowLoading: false,
      params: [],
      pagination: {
        totalPages: 0,
        page: 1
      },
      corruptedRowModel: {
        phone: '',
        variables: []
      },
      fixCorruptedErrors: {},
      currencies: currencies
    }
  },
  mounted() {
    this.getCorruptedRows()
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.getCorruptedRows(page)
    },
    removeDuplicateParams(arr) {
      const uniqueArray = arr.filter((value, index) => {
        return (
          index ===
          arr.findIndex(obj => {
            return obj.variable_name === value.variable_name
          })
        )
      })

      return uniqueArray
    },
    async getCorruptedRows(page = 1) {
      this.corruptedRowLoading = true
      const result = await orderConfirmationService.getCorruptedRows(
        this.$route.params.id,
        page
      )
      const { corruptedRows, scenarioId, ...pagination } = result
      this.corruptedRows = corruptedRows

      const paramsResult = await orderConfirmationService.getCallScenarioParams(
        scenarioId
      )
      // remove duplicates
      const ignoredParams = ['orderNumber']
      const uniqueParams = this.removeDuplicateParams(paramsResult)
      this.params = uniqueParams.filter(
        param => !ignoredParams.includes(param.variable_name)
      )

      const defaultValues = {
        money: {},
        text: '',
        'separated-number': '',
        'full-number': ''
      }

      this.corruptedRowModel = {
        ...this.corruptedRowModel,
        variables: uniqueParams
          .filter(param => !ignoredParams.includes(param.variable_name))
          .map(param => ({
            variable_name: param.variable_name,
            value: JSON.parse(JSON.stringify(defaultValues[param.type]))
          }))
      }

      this.corruptedRows = corruptedRows.map(row => ({
        ...row,
        corruptedKeys: Object.keys(row.row),
        corruptions: row.row
      }))

      this.corruptedRowLoading = false
      this.pagination.totalPages = pagination.totalPages
    },
    async onIgnore(id) {
      await orderConfirmationService.ignoreCorruptedRow(id)
      this.getCorruptedRows()
    },
    toggleDetails(row) {
      const variableValue = param => {
        if (!!row.row) {
          if (param.type === 'money') {
            const [amount, currency] = row.row[param.variable_name].split(':')
            return {
              amount,
              currency
            }
          }
          return row.row[param.variable_name]
        }
        return ''
      }

      this.corruptedRowModel = {
        ...this.corruptedRowModel,
        id: row.id,
        phone: row.row.phone,
        orderNumber: row.row.orderNumber,
        variables: this.params.map(param => ({
          variable_name: param.variable_name,
          value: variableValue(param)
        }))
      }

      this.$refs.corruptedCallsTable.toggleDetails(row.id)
    },
    async onCancel() {
      try {
        await orderConfirmationService.cancelCallFile(this.$route.params.id)
        this.$router.push({ name: 'order-confirmation-excel-calls-list' })
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    },
    async ignoreAll() {
      try {
        await orderConfirmationService.ignoreAllCorrupted(this.$route.params.id)
        this.$router.push({ name: 'order-confirmation-excel-calls-list' })
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    },
    async fixCorruptedRow() {
      try {
        await this.fixCorruptedSchema
          .validate(this.corruptedRowModel, { abortEarly: false })
          .catch(err => {
            this.fixCorruptedErrors = yupToKV(err)
          })

        if (!this.fixCorruptedSchema.isValidSync(this.corruptedRowModel)) {
          return
        }

        this.fixCorruptedErrors = {}
        await orderConfirmationService.fixCorruptedRow(
          this.corruptedRowModel.id,
          this.corruptedRowModel
        )
        this.getCorruptedRows(this.pagination.page)
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    }
  },
  computed: {
    fixCorruptedSchema() {
      return object().shape({
        phone: string()
          .trim()
          .test(
            'isValidPhone',
            this.$t(
              'order-confirmation.calls.excel.single-excel.table.details.errors.invalid.phone'
            ),
            isValidPhoneNumber
          )
          .required(
            this.$t(
              'order-confirmation.calls.excel.single-excel.table.details.errors.required.phone'
            )
          )
      })
    },
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    }
  }
}
</script>

<style></style>
